import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import LinkIcon from "@material-ui/icons/Link";
import React from "react";



export default function OptionalWebsite(props) {
    const { site } = props;
    if (site !== null) {
        return (
            <Box ml={"1%"} sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' }, alignItems: 'center', }}>
                <LinkIcon />
                <Link href={`https://${site}`}>
                    {site}
                </Link>
            </Box>
        )

    } else {
        return <div></div>
    }
}